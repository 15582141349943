<template>
  <div class="bg-hp-gray" style="min-height: 100vh;">
    <header class="w-full">
      <Menu class="bg-hp-gray" />
    </header>


    <!-- Main content -->
    <div class="pt-36 pb-40 max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4" style="min-height: 100vh;">
      <div class="text-center text-blue-900 font-bold text-4xl mb-16">
        Cecilia's Story
      </div>

      <div class="py-12 flex flex-wrap gap-y-4 gap-x-12 justify-between text-blue-900">
        <div>
          <div class="font-bold text-lg">Location</div>
          <div class="text-lg">California</div>
        </div>

        <div>
          <div class="font-bold text-lg">Situation</div>
          <div class="text-lg">18 year homeowner</div>
        </div>

        <div>
          <div class="font-bold text-lg">Job</div>
          <div class="text-lg">Secure insurance, Shed teardown</div>
        </div>

        <div>
          <div class="font-bold text-lg">Saved with Homeplan</div>
          <div class="text-lg">$2,100</div>
        </div>

        <div>
          <div class="font-bold text-lg">Status</div>
          <div class="text-lg">Before and After</div>
        </div>
      </div>

      <div class="grid grid-cols-3 md:grid-cols-6 grid-rows-2 py-4 gap-4">
        <div class="md:col-span-2 md:row-span-2 relative">
          <img class="w-full absolute z-0 rounded h-full object-cover" src="@/assets/case-studies/c/before/Ceci Before 2.jpg" alt="">
          <div style="border-color: #FCFAFD;" class="absolute -top-1.5 -left-1.5 z-100 py-2 px-6 bg-blue-200 text-blue-900 rounded-tl rounded-tr rounded-br font-semibold border-t-6 border-l-6 border-r-8 border-b-8">Before</div>
        </div>
        
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/c/before/ceci before 3.jpg" alt="">

        <div class="md:col-span-2 md:row-span-2 relative">
          <!-- <img class="w-full absolute z-0 rounded h-full object-cover" src="@/assets/case-studies/a/before/alex 25.jpg" alt=""> -->
          <div class="w-full absolute z-0 rounded h-full object-cover bg-gray-300" />

          <div style="border-color: #FCFAFD;" class="absolute -top-1.5 -left-1.5 z-100 py-2 px-6 bg-blue-200 text-blue-900 rounded-tl rounded-tr rounded-br font-semibold border-t-6 border-l-6 border-r-8 border-b-8">After</div>
        </div>
        
        <!-- <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/a/before/alex 22.jpg" alt=""> -->
        <div class="w-full rounded h-36 sm:h-48 object-cover bg-gray-300" />
        
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/c/before/ceci before 4.jpg" alt="">

        <!-- <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/a/before/alex 22.jpg" alt=""> -->
        <div class="w-full rounded h-36 sm:h-48 object-cover bg-gray-300" />
      </div>

      <div class="mt-12 mb-40 text-lg text-blue-900">
        <span class="text-4xl font-bold text-blue-900 mr-2">Cecilia</span>had gone 7 years without homeowners insurance and needed help around her home. She hadn't done any maintenance in a decade and only had the occasional help from her son-in-law to fix things reactively.
      
        <br /><br />

        Bash was brought in to tear down her shed and put together an annual maintenance lists and survey her home for any potential problem areas to be addressed.
        
        <br /><br />
        
        During his assessment he asked Cecilia if she had homeowners insurance and was informed that she did not. He introduced her to Homeplans insurance partner, Partners Insurance to get expert help finding the right insurance for her home. Partners spends time educating homeowners on the nuances of insurance to make sure there are no coverage gaps.
        
        <br /><br />
        
        This project is ongoing as Bash is in the process of buiding a new pergola and fixing her concrete porch.
        
        <br /><br />
        
        *Please note, after photos will be posted soon.
      </div>
    </div>


    
    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
import Menu from "@/components/menu";
import Footer from "@/components/large-footer.vue";

export default {
  components: {
    Menu,
    Footer
  },
  methods: {
    goTo(link) {
      window.scrollTo(0, 0);
      this.$router.push(link);
    }
  }
}
</script>


<style scoped>
header {
  widows: 100%;
  position: fixed;
  z-index: 1000;
}
</style>